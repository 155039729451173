import isNull from "lodash/isNull";
import {
  matchRoutes,
  Navigate,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";

import { menuRoutes } from "./services/routes";

import "./App.css";
import { ReactNode } from "react";



function App() {
  const location = useLocation();
  const matchedRoutes = matchRoutes([...menuRoutes], location.pathname);
  return (
    <Routes>
      {[...menuRoutes].map(({ key, path, component }) => (
        <Route key={key} path={path} element={component as unknown as ReactNode} />
      ))}
      {isNull(matchedRoutes) && (
        <Route path={location.pathname} element={<Navigate to="/" />} />
      )}
    </Routes>
  );
}

export default App;
