import { ROUTE_KEYS } from '../const/route-keys';
import Home from '../pages/Home'


interface PageRouteInterface {
  path: string,
  component: object,
  key: string
}

export const menuRoutes: Array<PageRouteInterface> = [
  {
    path: '/',
    component: <Home />,
    key: ROUTE_KEYS.root,
  },
  {
    path: '/home',
    component: <Home />,
    key: ROUTE_KEYS.root,
  },
];
