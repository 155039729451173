import React, { ReactNode, useEffect, useRef } from "react";

import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import LinkIcon from "@mui/icons-material/Link";

import { gsap } from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

import useMediaQueries from "../../services/media";

import "./styles.scss";

interface ProjectProps {
  desktopImgs: Array<string>;
  desktopVids: any;
  mobileImgs: Array<string>;
  sectionHeight: number;
  descHeight: number;
  title?: string;
  link?: string;
  descriptionText?: string | ReactNode;
  techStack?: Array<string>;
}

function ProjectItem({
  desktopImgs,
  desktopVids,
  mobileImgs,
  sectionHeight,
  descHeight,
  title,
  link,
  descriptionText,
  techStack,
}: ProjectProps) {
  gsap.registerPlugin(ScrollTrigger);
  const [isMobileView] = useMediaQueries();

  const ref = useRef<HTMLDivElement>(document.createElement("div"));

  useEffect(() => {
    if (!isMobileView) {
      const element = ref.current;
      gsap.fromTo(
        element.querySelectorAll(".mobile-screenshot"),
        {
          position: "relative",
          top: "0px",
          opacity: 1,
        },
        {
          position: "relative",
          top: "-35vh",
          opacity: 1,
          scrollTrigger: {
            scrub: true,
            trigger: element.querySelector(".mobile-screenshot"),
            start: "40px 300px",
            end: `bottom`,
            toggleClass: "is-scrolling",
          },
        }
      );
    }
  }, [null]);

  useEffect(() => {
    if (!isMobileView) {
      const element = ref.current;
      gsap.fromTo(
        element.querySelectorAll(".desktop-screenshot"),
        {
          position: "relative",
          top: "0px",
          opacity: 1,
        },
        {
          position: "relative",
          top: "-90vh",
          opacity: 1,
          scrollTrigger: {
            scrub: true,
            trigger: element.querySelector(".desktop-screenshot"),
            start: "40px 300px",
            end: `bottom`,
            toggleClass: "is-scrolling",
          },
        }
      );
    }
  }, [null]);

  useEffect(() => {
    if (!isMobileView) {
      const element = ref.current;
      gsap.fromTo(
        element.querySelectorAll(".description"),
        {
          position: "sticky",
          top: "20px",
          opacity: 1,
        },
        {
          position: "sticky",
          top: "20px",
          opacity: 1,
          scrollTrigger: {
            scrub: true,
            trigger: element.querySelector(".container"),
            start: "0px top",
            end: `100%`,
          },
        }
      );
    }
  }, [null]);

  return (
    <Grid
      container
      ref={ref}
      spacing={2}
      style={!isMobileView ? { height: `${!sectionHeight}px` } : {}}
      className="container"
    >
      <Grid item xs={12} md={4} sx={{ paddingTop: "10px" }}>
        <Box
          className="description"
          sx={{
            height: !isMobileView ? `${descHeight}px` : "auto",
          }}
        >
          <Typography variant="h4" style={{ paddingBottom: "20px" }}>
            {link ? (
              <a href={link} className="desc-title-link" target="_blank">
                {title}
                <LinkIcon />
              </a>
            ) : (
              title
            )}
          </Typography>
          <Typography variant="body1" style={{padding: '1px 20px', background: 'rgba(0,0,0,.5)'}}>{descriptionText}</Typography>
          <Typography>
            <ul style={{ listStyleType: "none", padding: "0px 0" }}>
              {techStack?.map((item) => (
                <li style={{ margin: "5px 0" }}>
                  <Chip
                    label={item}
                    sx={{
                      borderRadius: "4px",
                      background: "#000",
                      opacity: 0.85,
                      color: "white",
                      boxShadow: "1px 1px 1px 0px rgba(0,0,0,.2)",
                      float: "left",
                      margin: "5px 10px 5px 0",
                    }}
                  />
                </li>
              ))}
            </ul>
          </Typography>
        </Box>
      </Grid>
      {mobileImgs && mobileImgs.length > 0 && (
        <Grid item xs={12} md={3} className="mobile-screenshot">
          {mobileImgs.slice(0,1).map((picture: any) => (
            <img src={picture} style={{ maxWidth: "100%" }} />
          ))}
        </Grid>
      )}
      <Grid item xs={12} md={5} className="desktop-screenshot">
        {desktopVids &&
          desktopVids.map((vid: any) => (
            <video width="1024" autoPlay loop muted>
              <source src={vid} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          ))}
        {desktopImgs.map((picture: any) => (
          <img src={picture} />
        ))}
      </Grid>
    </Grid>
  );
}

export default ProjectItem;
