import { createTheme } from "@mui/material/styles";

const defaultTheme = createTheme({});
const {
  breakpoints,
  typography: { pxToRem },
} = defaultTheme;

const theme = createTheme({
  palette: {
    primary: {
      main: "#000000",
    },
  },
  typography: {
    h1: {
      fontSize: "3.8rem",
      fontWeight: "900",
      color: "#000",
      [breakpoints.down("md")]: {
        fontSize: "38px",
      },
    },
    h2: {
      fontSize: "2.6rem",
      fontWeight: "900",
      margin: "2rem 0",
      color: "rgba(0,0,0, 1)",
    },
    h4: {
      fontWeight: 300,
      fontSize: "42px",
      margin: "0px 0 0px",
    },
    h6: {
      fontWeight: "600",
      margin: "20px 0",
      color: "rgba(34, 34, 35, 1)",
    },
    h5: {
      fontSize: "18px",
      color: "#222223",
      fontWeight: "800",
    },
    subtitle1: {
      fontSize: "1.15rem",
      color: "#222223",
    },
    subtitle2: {
      fontSize: "35px",
      color: "#222223",
      fontWeight: 400,
    },
    body1: {
      color: "#222223",
      fontSize: "18px",
      lineHeight: "200%",
      fontWeight: 300,
    },
    body2: {
      color: "#000000",
      fontSize: "18px",
      lineHeight: "29px",
      fontWeight: 400,
    },
    fontFamily: [
      "Roboto",
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          padding: "10px 10px",
          minWidth: "32px",
          background: "#c05300",
          color: 'white',
          boxShadow: "4px 4px 0 0 rgba(0,0,0,.1)",
          borderRadius: "0px",
          "&:hover": {
            background: "#9C3400",
            opacity: 1,
            boxShadow: "4px 4px 0 0 rgba(0,0,0,.1)",
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          background: "white",
          "& .MuiOutlinedInput-root": {
            "& > fieldset": {
              border: "1px solid #DEDEDE",
            },
          },
        },
      },
    },
  },
});

export default theme;
