import React, { useRef, useEffect } from "react";
import Grid from "@mui/material/Grid";

import bg1 from "../assets/main_bg_1.webp";
import bg2 from "../assets/main_bg_7.webp";
import bg3 from "../assets/main_bg_8.webp";
import bg4 from "../assets/main_bg_4.webp";
import bg5 from "../assets/main_bg_5.webp";

import { gsap } from "gsap";

import Header from "../components/Header";

import './styles.scss'

interface Props {
  children?: React.ReactNode;
  // any props that come into the component
}

const backgrounds = [bg1, bg2, bg3, bg4, bg5];

function MainTemplate({ children }: Props) {
  const [bgNum, setBgNum] = React.useState(Math.floor(Math.random() * 5));
  // generate random number from 1 to 5 for background image
  const ref = useRef<HTMLDivElement>(document.createElement("div"));

  useEffect(() => {
    gsap.fromTo(
      document.querySelectorAll(".img-bg"),
      {
        opacity: 1,
        backgroundColor: "#000",
      },
      {
        opacity: 0.1,
        backgroundColor: "#000",
        scrollTrigger: {
          scrub: true,
          trigger: document.querySelectorAll(".img-bg"),
          start: "240px 300px",
          end: `bottom`,
          toggleClass: "is-scrolling",
        },
      }
    );
  }, [null]);

  console.log(backgrounds[bgNum]);

  return (
    <Grid
      container
      sx={{ maxWidth: "1400px", margin: "0 auto", height: "3000px" }}
    >
      <div
        className="img-bg"
        style={{ backgroundImage: `url(${backgrounds[bgNum]}` }}
      />
      <Header />
      <Grid item xs={12}>
        {children}
      </Grid>
    </Grid>
  );
}
export default MainTemplate;
