import * as React from "react"

const SvgComponent = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 256 256"
    xmlSpace="preserve"
  >
    <g
      style={{
        stroke: "none",
        strokeWidth: 0,
        strokeDasharray: "none",
        strokeLinecap: "butt",
        strokeLinejoin: "miter",
        strokeMiterlimit: 10,
        fill: "none",
        fillRule: "nonzero",
        opacity: 1,
      }}
    >
      <path
        d="M45 0C20.147 0 0 20.147 0 45s20.147 45 45 45 45-20.147 45-45S69.853 0 45 0zm25 25.993-3.992 3.827a1.167 1.167 0 0 0-.444 1.12v28.12c-.071.427.099.858.444 1.12l3.898 3.827v.84H50.299v-.84l4.038-3.92c.397-.397.397-.513.397-1.12v-22.73L43.506 64.754h-1.517L28.917 36.237V55.35a2.637 2.637 0 0 0 .724 2.194l5.252 6.371v.84H20v-.84l5.252-6.371a2.542 2.542 0 0 0 .677-2.194v-22.1a1.936 1.936 0 0 0-.63-1.633l-4.669-5.624v-.84h14.496L46.33 49.726l9.851-24.573H70v.84z"
        style={{
          stroke: "none",
          strokeWidth: 1,
          strokeDasharray: "none",
          strokeLinecap: "butt",
          strokeLinejoin: "miter",
          strokeMiterlimit: 10,
          fill: "#fff",
          fillRule: "nonzero",
          opacity: 1,
        }}
        transform="matrix(2.81 0 0 2.81 1.407 1.407)"
      />
    </g>
  </svg>
)

export default SvgComponent
