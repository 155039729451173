import React from "react";

import Box from "@mui/material/Box";
import GitHubIcon from "@mui/icons-material/GitHub";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import Button from "@mui/material/Button";
import { Link } from "@mui/material";

import Logo from "./assets/logo.png";
import MediumIcon from "../Icons/Medium";

import "./styles.scss";

function Header() {
  return (
    <Box sx={{ padding: "60px 0" }} className="header-wrapper">
      <span className="header-logo fade-in">
        ALEX SEKOWSK<span>I</span>
      </span>
      {/* <span className="casestudytxt">
        Selected portfolio of career case studies
      </span> */}
      <br />

      <Box className="contact-bar fade-in-delay">
        <Link href="https://www.github.com/aleksUIX" target="_blank">
          <Button variant="contained">
            <GitHubIcon />
          </Button>
        </Link>
        <Link
          href="https://www.linkedin.com/in/aleksandersekowski/"
          target="_blank"
        >
          <Button variant="contained">
            <LinkedInIcon />
          </Button>
        </Link>
        <Link href="https://medium.com/@aleksander-sekowski" target="_blank">
          <Button variant="contained">
            <MediumIcon />
          </Button>
        </Link>
      </Box>
    </Box>
  );
}

export default Header;
