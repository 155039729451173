import React from "react";

import Typography from "@mui/material/Typography";

import MainTemplate from "../../containers/MainTemplate";
import ProjectItem from "../../components/ProjectItem";

import carbonMobile from "../../assets/carbon_method/carbon-mobile.webp";
import carbonDesktop from "../../assets/carbon_method/carbon-desktop.webp";
import carbonDesktop2 from "../../assets/carbon_method/carbon-desktop2.webp";
import carbonDesktop3 from "../../assets/carbon_method/carbon-desktop3.webp";

import deutscheMobile from "../../assets/deutscheinvest/deutsche-mobile.webp";
import deutscheMobile2 from "../../assets/deutscheinvest/deutsche-mobile-2.webp";
import deutscheMobile3 from "../../assets/deutscheinvest/deutsche-mobile-3.webp";
import deutscheDesktop from "../../assets/deutscheinvest/deutsche-desktop.webp";
import deutscheDesktop2 from "../../assets/deutscheinvest/deutsche-desktop-2.webp";
import deutscheDesktop3 from "../../assets/deutscheinvest/deutsche-desktop-3.webp";
import deutscheDesktop4 from "../../assets/deutscheinvest/deutsche-desktop-4.webp";

import bpDesktop from "../../assets/bp/bp-desktop.webp";
import bpDesktop2 from "../../assets/bp/bp-desktop2.webp";
import bpMobile from "../../assets/bp/bp-mobile.webp";

import o2Mobile from "../../assets/o2/o2-mobile.webp";
import o2Desktop from "../../assets/o2/o2-desktop.webp";
import o2Desktop2 from "../../assets/o2/o2-desktop2.webp";

import clarityDesktop from "../../assets/clarity/clarity-desktop.webp";
import clarityDesktop2 from "../../assets/clarity/clarity-desktop2.webp";
import clarityDesktop3 from "../../assets/clarity/clarity-desktop3.webp";

import newshackerDesktop from "../../assets/newshacker/newshacker-desktop.webp";
import newshackerDesktop1 from "../../assets/newshacker/newshacker-desktop1.webp";
import newshackerDesktop2 from "../../assets/newshacker/newshacker-desktop2.webp";
import newshackerDesktop3 from "../../assets/newshacker/newshacker-desktop3.webp";

const projects = [
  {
    desktopImgs: [bpDesktop, bpDesktop2],
    mobileImgs: [bpMobile],
    desktopVids: [],
    sectionHeight: 5000,
    descHeight: 2100,
    title: "bp handbook",
    descriptionText: (
      <>
        <p>
        Decreased development time by 30-50% being the first team to implement inhouse SDK and component library.	I promoted and implemented architectures such as Serverside Rendering, headless CMS and cloud functions.	I led in an Agile team of 4 full stack developers in building a React / Node.js single page application
        </p>
      </>
    ),
    techStack: [
      "React",
      "React Native",
      "Redux",
      "d3.js",
      "Microservices",
      "GraphQL",
      "Oauth2",
      "TDD",
      "module design",
      "reusability",
    ],
  },
  {
    desktopImgs: [carbonDesktop2, carbonDesktop, carbonDesktop3],
    mobileImgs: [carbonMobile],
    sectionHeight: 2400,
    descHeight: 1600,
    title: "The Carbon Method",
    link: "https://www.thecarbonmethod.com",
    descriptionText: (
      <>
        <p>
          As a Lead Engineer on this project, I helped the product owners create
          the vision and breathe life into it. I joined the project at very
          early stages and focused on helping move it from an idea stage to a
          finished product.
        </p>
        <p>
          My work encompassed working on early high level concepts, architecture
          design, tech stack evaluation and selection, production pipeline
          management, boosting developer productivity via code reviews and
          advising on best practices.
        </p>
        <p>
          Our biggest achievement on this project was{" "}
          <strong>launching a well-rounded web app within four months</strong>{" "}
          from initial prototype design.
        </p>
      </>
    ),
    techStack: [
      "React",
      "d3.js",
      "greensock",
      "Postgres",
      "Serverless",
      "system design",
      "pipeline management",
    ],
  },
  {
    desktopImgs: [
      deutscheDesktop,
      deutscheDesktop2,
      deutscheDesktop3,
      deutscheDesktop4,
    ],
    mobileImgs: [deutscheMobile, deutscheMobile2, deutscheMobile3],
    sectionHeight: 5000,
    descHeight: 2100,
    title: "DWS Robo Advisor",
    link: "https://www.deutscheinvest.lu",
    descriptionText: (
      <>
        <p>
          I worked on an AI powered financial Robo-advisor; web and mobile
          account management and a cross project component platform. My
          responsibilities included architecting a fully component based
          interface and SDK, as well as best development and system design
          practices.
        </p>
        <p>
          By the end of my work at Deutsche Bank my teams and I{" "}
          <strong>released to production an innovative product</strong> across
          multiple geographies, locales and for various demographics. We
          involved other DB departments in using and contributing to our SDK and
          provided extensive documentation on the products, infrastructure and
          code.
        </p>
      </>
    ),
    techStack: [
      "React",
      "React Native",
      "Redux",
      "d3.js",
      "Microservices",
      "GraphQL",
      "Oauth2",
      "TDD",
      "module design",
      "reusability",
    ],
  },
  {
    desktopImgs: [
      newshackerDesktop,
      newshackerDesktop1,
      newshackerDesktop2,
      newshackerDesktop3,
    ],
    mobileImgs: [],
    sectionHeight: 3000,
    descHeight: 800,
    title: "Newshacker & Twitterstream",
    descriptionText: (
      <>
        <p>
          In my role as lead engineer I worked to design, build and deploy a
          real time news data analysis application. The web app serves market
          and news data in real-time saving time and resources by offering a
          bird's eye view of the news cycle.
        </p>
        <p>
          The main challenges I tackled was setting up data streams for
          automatic processing and analysis through web services and funneling
          the results to a single page web application. I also supported efforts
          in training NLP models for classification, summarization and question
          answering.
        </p>
      </>
    ),
    techStack: [
      "data analytics",
      "visualization",
      "React",
      "d3.js",
      "material-ui",
      "webpack",
      "serverless functions",
      "Google Cloud",
    ],
  },
  {
    desktopImgs: [o2Desktop, o2Desktop2],
    mobileImgs: [o2Mobile],
    sectionHeight: 6000,
    descHeight: 1300,
    title: "O2 Online",
    link: "https://www.o2.co.uk",
    descriptionText: (
      <>
        <p>
          I helped lead a team of front-end developers and UX designers in a
          mission to increase customer conversion on O2 e-commerce platform. We
          progressively updated the interface and checkout user flows and
          collected user engagement metrics including sessions camera recordings
          and web analytics.
        </p>
        <p>
          Our goal was to iteratively improve user experience from design and
          technology standpoint. We regularly validated our results with A/B
          testing and measured the impact. Our team delivered a significant
          improvement over the baseline and{" "}
          <strong>
            allowed the organization to generate more revenue per visitor
          </strong>
          .
        </p>
      </>
    ),
    techStack: [
      "React",
      "javascript",
      "HTML5/CSS3",
      "Microservices",
      "A/B Testing",
      "Conversion",
    ],
  },
  {
    desktopImgs: [clarityDesktop3, clarityDesktop, clarityDesktop2],
    // mobileImgs: [clarityMobile, clarityMobile2],
    mobileImgs: [],
    sectionHeight: 2400,
    descHeight: 800,
    title: "Clarity AI",
    descriptionText: (
      <>
        <p>
          My main responsibility was to contribute to and support a data
          analytics project from the early domain stages through processing and
          analyzing to deployment. I helped create a Node.js / Electron app for
          extracting data from traditional market publications and a python
          based natural language processing service to extract insights from the
          resulting datapoints.
        </p>
        <p>
          My work also included working with a team of data analysts and traders
          to devise a systematic approach to serve and visualize the data. Our
          main{" "}
          <strong>
            accomplishment was deploying the project to the trading desk
          </strong>{" "}
          where the data made it's way into market analysis and trading models .
        </p>
      </>
    ),
    techStack: [
      "Node.js",
      "Electron",
      "Python",
      "websockets",
      "tensorflow",
      "🤗 transformers",
    ],
  },
];

function HomePage() {
  return (
    <MainTemplate>
      {projects.map(
        ({
          desktopImgs,
          desktopVids,
          mobileImgs,
          sectionHeight,
          descHeight,
          title,
          link,
          descriptionText,
          techStack,
        }) => {
          return (
            <ProjectItem
              desktopImgs={desktopImgs}
              desktopVids={desktopVids}
              mobileImgs={mobileImgs}
              sectionHeight={sectionHeight}
              descHeight={descHeight}
              title={title}
              link={link}
              descriptionText={descriptionText}
              techStack={techStack}
            />
          );
        }
      )}
    </MainTemplate>
  );
}

export default HomePage;
